<div id="nav" [class.extended]="menuActive">
    <button class="menu-toggle" [class.active]="menuActive" type="button" (click)="toggleMenuActive()">
        <span class="menu-toggle-inner"></span>
    </button>
    <div class="nav-body">
        <div id="nav-headline" class="h3" routerLink="/{{nav.component}}">{{nav.headline}}</div>
        <div id="nav-accordion">
            <div *ngFor="let node of nav.tree; let i = index">
                <ng-container
                        *ngIf="node.requiredAuthority == null || userService.hasRequiredAuthority(node.requiredAuthority) ">
                    <ng-container *ngIf="!node.fragment">
                        <a id="heading{{i}}"
                           (click)="node.tree && node.tree.length > 0? false : menuInactive();"
                           class="{{(node.tree && node.tree.length > 0) ? 'accordion-headline' : 'accordion-headline-no-children'}} collapsed"
                           routerLink="{{node.path ? '/'+node.path : ''}}"
                           data-toggle="collapse"
                           attr.data-target="#collapse{{i}}"
                           aria-expanded="true"
                           attr.aria-controls="collapse{{i}}"
                           [class.active]="active == node.path && active != 'dashboard' && node.path != null"
                           attr.data-cy="nav-item-{{node.dataCy}}">{{node.navLinkName}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="node.fragment">
                        <a id="heading{{i}}"
                           class="{{(node.tree && node.tree.length > 0) ? 'accordion-headline' : 'accordion-headline-no-children'}} collapsed"
                           routerLink="{{node.path ? '/'+node.path+'/' : ''}}"
                           [fragment]="node.fragment"
                           data-toggle="collapse"
                           attr.data-target="#collapse{{i}}"
                           aria-expanded="true"
                           attr.aria-controls="collapse{{i}}"
                           [class.active]="active == node.path && active != 'dashboard' && node.path != null"
                           attr.data-cy="nav-item-{{node.dataCy}}">{{node.navLinkName}}
                        </a>
                    </ng-container>
                    <ul id="collapse{{i}}" class="collapse" attr.aria-labelledby="heading{{i}}"
                        data-parent="#nav-accordion"
                        *ngIf="node.tree && node.tree.length > 0">
                        <!--<ul *ngIf="node.tree && node.tree.length > 0">-->

                        <ng-container *ngFor="let childNode of node.tree">
                            <li
                                    *ngIf="childNode.requiredAuthority == null || userService.hasRequiredAuthority(childNode.requiredAuthority) " id="childNode{{i}}">
                                <a routerLink="/{{childNode.path}}"
                                   [class.active]="active == childNode.path && active != 'dashboard'"
                                   attr.data-cy="nav-item-{{childNode.dataCy}}">
                                    {{childNode.navLinkName}}
                                </a>
                            </li>
                        </ng-container>

                        <!--</ul>-->
                    </ul>

                </ng-container>
            </div>
        </div>
    </div>
</div>
