import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subject, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CommunicationRestService extends RestServiceAbstract {

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();

    public loadTemplates() {
        return this.get(`/usermanagement/admin/communication/templates`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public saveTemplates(rq) {
        return this.post(`/usermanagement/admin/communication/templates`,rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public editTemplates(rq) {
        return this.put(`/usermanagement/admin/communication/templates`,rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public deleteTemplates(rq) {
        return this.delete(`/usermanagement/admin/communication/templates`,{body: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }


    public loadMessages() {
        return this.get(`/usermanagement/admin/communication/messages`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public saveMessage(rq) {
        return this.post(`/usermanagement/admin/communication/messages`,rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public editMessage(rq) {
        return this.put(`/usermanagement/admin/communication/messages`,rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public deleteMessage(rq) {
        return this.delete(`/usermanagement/admin/communication/messages`,{body: rq}).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public publishMessage(rq){
        return this.post(`/usermanagement/admin/communication/messages/publish`,  rq).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getMessage(rq){
        return this.get(`/usermanagement/admin/communication/messages/${rq}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
